export default class PageSection {
	type: number;
	content: any;

	constructor() {
		this.type = 0;
		this.content = null;
	}

	public static HEADER_TYPE = 1;
	public static FOOTER_TYPE = 2;
	public static PAGE_SECTION_HEADER_TYPE = 3;
	public static PAGE_SECTION_SOLUTIONS_TYPE = 4;
	public static PAGE_SECTION_BANNER_TYPE = 5;
	public static PAGE_SECTION_PRODUCT_GRID_TYPE = 6;

	public static PAGE_SECTION_HEADER_PRODUCT_TYPE = 7;
	public static PAGE_SECTION_HIGHLIGHT_POINTS_TYPE = 8;
	public static PAGE_SECTION_PRODUCT_CONFIGURATION_TYPE = 9;
	public static PAGE_SECTION_PRODUCT_FEATURES_TYPE = 10;
	public static PAGE_SECTION_PRODUCT_DATA_SHEET_TYPE = 11;
	public static PAGE_SECTION_PRODUCT_FAQS_TYPE = 12;

	public static copy(model: any) {
		const aux = new PageSection();
		aux.type = model.id;
		aux.content = model.title;
		return aux;
	}
}
