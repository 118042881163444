





















import { Component, Vue, Prop } from 'vue-property-decorator';

@Component({
	components: {},
})
export default class PageSectionFaqs extends Vue {
	@Prop({ type: Object, required: true }) content: any;
}
