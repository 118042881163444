var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('section',{staticClass:"banner"},[_c('b-container',[_c('div',{staticClass:"banner-content"},[_c('h2',{staticClass:"banner-title"},[_vm._v(_vm._s(_vm.content.title))]),_vm._v(" "),_c('p',{staticClass:"banner-text"},[_vm._v(_vm._s(_vm.content.description))]),_vm._v(" "),_c('ul',{staticClass:"banner-features"},_vm._l((_vm.content.highlight_points),function(feature,index){return _c('li',{key:'feature-' + index},[_vm._v(_vm._s(feature.title))])}),0)]),_vm._v(" "),_c('div',{staticClass:"banner-form"},[_c('ValidationObserver',{ref:"simple_demo_obs",attrs:{"slim":""},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var passes = ref.passes;
var validate = ref.validate;
return [_c('b-form',{attrs:{"inline":""},on:{"submit":function($event){$event.preventDefault();validate();
						passes(_vm.onSimpleDemo);}}},[_c('div',{staticClass:"fields-row"},[_c('ValidationProvider',{attrs:{"name":"email","rules":"required|email"},scopedSlots:_vm._u([{key:"default",fn:function(ref){
						var errors = ref.errors;
						var valid = ref.valid;
return [_c('InputText',{attrs:{"errors":errors,"valid":valid,"sr_only":true,"type":"email","label":"Email","id":"demo_mail","placeHolder":"Email","model":_vm.email},model:{value:(_vm.email),callback:function ($$v) {_vm.email=$$v},expression:"email"}})]}}],null,true)}),_vm._v(" "),_c('ValidationProvider',{attrs:{"name":"phone","rules":{ required: true, numeric: true, length: 9 }},scopedSlots:_vm._u([{key:"default",fn:function(ref){
						var errors = ref.errors;
						var valid = ref.valid;
return [_c('InputText',{attrs:{"errors":errors,"valid":valid,"sr_only":true,"type":"tel","label":"Teléfono","id":"demo_phone","placeHolder":"000 000 000","model":_vm.phone},model:{value:(_vm.phone),callback:function ($$v) {_vm.phone=$$v},expression:"phone"}})]}}],null,true)}),_vm._v(" "),_c('ValidationProvider',{attrs:{"name":"product","rules":"required"},scopedSlots:_vm._u([{key:"default",fn:function(ref){
						var errors = ref.errors;
						var valid = ref.valid;
return [_c('PageSelect',{attrs:{"errors":errors,"valid":valid,"sr_only":true,"type":"tel","label":"Producto","id":"demo_product","model":_vm.product},model:{value:(_vm.product),callback:function ($$v) {_vm.product=$$v},expression:"product"}})]}}],null,true)})],1),_vm._v(" "),_c('b-form-group',{attrs:{"label-sr-only":"","label":"Privacidad y promociones"},scopedSlots:_vm._u([{key:"default",fn:function(ref){
						var ariaDescribedby = ref.ariaDescribedby;
return [_c('div',{},[_c('Checkbox',{attrs:{"id":"simple_demo_advertising","model":_vm.advertising,"values":{ checked: 'T', unchecked: 'F' }},model:{value:(_vm.advertising),callback:function ($$v) {_vm.advertising=$$v},expression:"advertising"}},[_vm._v("\n\t\t\t\t\t\t\t\tDeseo recibir información y promociones especiales de A3SATEL por cualquier medio, incluyendo los medios electrónicos\n\t\t\t\t\t\t\t")]),_c('br'),_vm._v(" "),_c('ValidationProvider',{attrs:{"name":"simple_demo_privacy","rules":{ required: { allowFalse: false } }},scopedSlots:_vm._u([{key:"default",fn:function(ref){
						var errors = ref.errors;
						var valid = ref.valid;
return [_c('Checkbox',{attrs:{"errors":errors,"valid":valid,"id":"simple_demo_privacy","model":_vm.simple_demo_privacy},model:{value:(_vm.simple_demo_privacy),callback:function ($$v) {_vm.simple_demo_privacy=$$v},expression:"simple_demo_privacy"}},[_vm._v("\n\t\t\t\t\t\t\t\t\tHe leído y acepto el "),_c('a',{staticClass:"blue-link",attrs:{"href":_vm.legalTermUrl,"target":"_blank"}},[_vm._v("aviso legal")]),_vm._v(" y la "),_c('a',{staticClass:"blue-link",attrs:{"href":_vm.anchor_policy,"target":"_blank"}},[_vm._v("política de privacidad")])])]}}],null,true)})],1)]}}],null,true)}),_vm._v(" "),_c('b-button',{attrs:{"variant":"primary","type":"submit"}},[_vm._v("Solicitar demo")])],1)]}}])})],1)])],1)}
var staticRenderFns = []

export { render, staticRenderFns }