import ApiHelper from '~/helpers/api-helper';

export default class PageService {
	public static PUBLISHED_STATUS = 1;
	public static UNPUBLISHED_STATUS = 0;

	public static getBySlug($axios: any, slug: string) {
		const data = {
			slug,
		};
		return $axios.$post('/page/slug', data);
	}

	public static getProductList($axios: any, filters: any, order: any) {
		const params = ApiHelper.setQueryParams(null, filters, order);
		return $axios.get(`/page/products`, { params });
	}

	public static getSelectorList($axios: any) {
		return $axios.get(`/page/filter`);
	}
}
