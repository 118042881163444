















































































import { Component, Vue, Prop } from 'vue-property-decorator';
import Product from '~/components/core/Product.vue';
import PageService from '~/services/PageService';

@Component({
	components: { Product },
})
export default class PageSectionSolutions extends Vue {
	@Prop({ type: Object, required: true }) content: any;

	private products_left = [];
	private products_right = [];

	mounted() {
		if (this.content.left_column.category) {
			this.getProducts('products_left', this.content.left_column.category);
		}
		if (this.content.right_column.category) {
			this.getProducts('products_right', this.content.right_column.category);
		}
	}

	getImgUrl(image: string) {
		return process.env.API_IMG_URL + image;
	}

	async getProducts(entity: any, category: any) {
		await PageService.getProductList(this.$axios, { category, status: PageService.PUBLISHED_STATUS }, { sortBy: 'updated_at', sortDesc: true })
			.then((res: any) => {
				if (res) {
					if (entity == 'products_left') {
						this.products_left = res.data.elements.slice(0, 3);
					} else {
						this.products_right = res.data.elements.slice(0, 3);
					}
				}
			})
			.catch((error: any) => {
				// ApiHelper.handleErrors(error, {}, this.$nuxt);
			});
	}
}
