



































import { Component, Vue, Prop } from 'vue-property-decorator';

@Component({
	components: {},
})
export default class PageSectionFeaturesAndServices extends Vue {
	@Prop({ type: Object, required: true }) content: any;
	@Prop({ required: false }) page: any;

}
