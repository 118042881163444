















import { Component, Vue, Prop } from 'vue-property-decorator';
import Feature from '~/components/core/Feature.vue';

@Component({
	components: { Feature },
})
export default class PageSectionHighLightPoints extends Vue {
	@Prop({ type: Object, required: true }) content: any;
}
