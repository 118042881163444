





















import { Component, Prop, Vue, Watch } from 'vue-property-decorator';
import PageService from '~/services/PageService';
import ApiHelper from '~/helpers/api-helper';

@Component
export default class PageSelect extends Vue {
	@Prop({ default: null, required: false }) private model: any | undefined;
	@Prop({ default: null, required: true }) private label: any | '';
	@Prop({ default: null, required: true }) private id: any | '';
	@Prop({ default: null, required: false }) private helpText: any | '';
	@Prop({ default: null, required: false }) private errors: any | [];
	@Prop({ default: null, required: false }) private valid: any | boolean;
	@Prop({ default: null, required: false }) private validFeedbackText: any | undefined;
	@Prop({ default: false, required: false }) private disabled: any | boolean;
	@Prop({ default: false, required: false }) private sr_only: any | boolean;

	private value = null;
	private options: any[] = [];

	@Watch('model')
	onModelChanged(model: any) {
		this.value = model;
	}

	mounted() {
		this.getList();
		if (this.model) {
			this.value = this.model;
		}
	}

	getList() {
		PageService.getSelectorList(this.$axios)
			.then((res: any) => {
				if (res.status === 200) {
					this.prepareOptions(res.data);
				}
			})
			.catch((error: any) => {
				ApiHelper.handleErrors(error, {}, this.$store);
			});
	}

	prepareOptions(data: any) {
		this.options = [{ text: '  Elige un producto...', value: null }];
		for (const page of data) {
			this.options.push({ text: page.title, value: page.title });
		}
	}

	get state() {
		return this.errors == null || this.errors.length == 0 ? null : this.errors.length == 0;
	}

	get invalidFeedback() {
		if (this.errors) {
			return this.errors[0];
		}
		return null;
	}

	get validFeedback() {
		if (this.validFeedbackText) {
			return !this.errors && this.valid ? this.validFeedbackText : '';
		}
		return '';
	}
}
