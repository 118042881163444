import PageSection from '~/models/page_section';

export default class Page {
	id: any;
	title: string;
	slug: string;
	seo_title: string;
	seo_description: string;
	og_image_url: string;
	ec_product_image_url: string;
	ec_product_id: any;
	ec_product_name: string;
	ec_product_external_ref: any;
	product_configurations: any[];
	subcategory_name: any;
	category_name: any;
	sections: PageSection[];
	parents: any[];
	created_at: any;

	constructor() {
		this.id = null;
		this.title = '';
		this.slug = '';
		this.seo_title = '';
		this.seo_description = '';
		this.og_image_url = '';
		this.ec_product_image_url = '';
		this.ec_product_id = null;
		this.ec_product_name = '';
		this.ec_product_external_ref = null;
		this.product_configurations = [];
		this.sections = [];
		this.parents = [];
		this.subcategory_name = null;
		this.category_name = null;
		this.created_at = null;
	}

	public static copy(model: any) {
		const aux = new Page();
		aux.id = model.id;
		aux.title = model.title;
		aux.slug = model.slug;
		aux.seo_title = model.seo_title;
		aux.seo_description = model.seo_description;
		aux.og_image_url = model.og_image_url;
		aux.ec_product_image_url = model.ec_product_image_url;
		aux.ec_product_id = model.ec_product_id;
		aux.ec_product_name = model.ec_product_name;
		aux.ec_product_external_ref = model.ec_product_external_ref;
		aux.product_configurations = model.product_configurations;
		aux.sections = model.sections;
		aux.parents = model.parents;
		aux.subcategory_name = model.subcategory_name;
		aux.category_name = model.category_name;
		aux.created_at = model.created_at;
		return aux;
	}
}
