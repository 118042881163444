
















































































import { Component, Vue, Prop } from 'vue-property-decorator';
import { ValidationProvider, ValidationObserver } from 'vee-validate';
import Checkbox from '~/components/inputs/Checkbox.vue';
import InputText from '~/components/inputs/InputText.vue';
import UserService from '~/services/UserService';
import ApiHelper from '~/helpers/api-helper';
import PageSelect from '~/components/inputs/PageSelect.vue';

@Component({
	components: {
		PageSelect,
		Checkbox,
		InputText,
		ValidationProvider,
		ValidationObserver,
	},
})
export default class PageSectionBanner extends Vue {
	@Prop({ type: Object, required: true }) content: any;

	private email: string = '';
	private phone: string = '';
	private product: any = null;
	private simple_demo_privacy: boolean = false;
	private advertising: string = 'F';
	private public_url = process.env.APP_URL;
	private anchor_policy = this.public_url + 'legalTerm/politicaprivacidad';
	private legalTermUrl = this.public_url + 'legalTerm/avisolegal';

	$refs!: {
		simple_demo_obs: InstanceType<typeof ValidationObserver>;
	};

	onSimpleDemo() {
		this.$nuxt.$loading.start();
		UserService.bitrix(this.$axios, {
			type: UserService.BITRIX_SIMPLE_DEMO_TYPE,
			data: { email: this.email, phone: this.phone, product: this.product, advertising: this.advertising },
		})
			.then((res: any) => {
				this.$nuxt.$loading.finish();
				if (res.status === 200) {
					this.$store.commit('setAlert', {
						show: true,
						message: '¡Solicitud enviada! Te contactaremos lo antes posible',
						type: 'success',
					});
					if (this.$refs.simple_demo_obs) {
						this.$refs.simple_demo_obs.reset();
					}
					this.email = '';
					this.phone = '';
					this.product = null;
					this.simple_demo_privacy = false;
					this.advertising = 'F';
				}
			})
			.catch((error: any) => {
				this.$nuxt.$loading.finish();
				ApiHelper.handleErrors(error, this.$refs.simple_demo_obs, this.$store);
			});
	}
}
