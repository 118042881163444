











import { Component, Vue, Prop } from 'nuxt-property-decorator';

@Component({})
export default class Product extends Vue {
	@Prop({ default: null, required: true }) private productTitle!: string;
	@Prop({ default: null, required: true }) private productDescription!: string;
	@Prop({ default: null, required: true }) private productRoute!: string;
	@Prop({ default: null, required: true }) private productRouteText!: string;
	@Prop({ default: null, required: true }) private productImage!: string;
	@Prop({ default: 'card-h', required: false }) private productClass!: string;

	get getImgUrl() {
		return process.env.API_IMG_URL + this.productImage;
	}

	goToProduct(route: string) {
		this.$store.commit('setGtmEvent', 'select_item');
		this.$router.push({ path: route });
	}
}
