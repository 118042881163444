




































import { Component, Vue, Prop } from 'vue-property-decorator';
import Breadcrumb from '~/components/core/Breadcrumb.vue';
import Slider from '~/components/core/Slider.vue';
import ModalDemo from '~/components/modals/ModalDemo.vue';

@Component({
	components: { ModalDemo, Slider, Breadcrumb },
})
export default class PageSectionHeader extends Vue {
	@Prop({ type: Object, required: true }) content: any;
	@Prop({ required: false }) ec_product_slug: any;
	@Prop({ required: false }) page: any;

	$refs!: {
		headerModalDemo: InstanceType<typeof ModalDemo>;
	};

	getImgUrl(image: string) {
		return process.env.API_IMG_URL + image;
	}

	showDemo(name: string) {
		this.$refs.headerModalDemo.show(name);
	}

	goToProduct(slug: string) {
		this.$store.commit('setGtmEvent', 'select_item');
		this.$router.push({ path: '/soluciones/' + slug });
	}
}
