


























import { Component, Vue, Prop } from 'vue-property-decorator';

@Component({})
export default class Slider extends Vue {
	@Prop({ type: Array, required: true }) slides: any;

	private slide: number = 0;
	private sliding: any = null;

	private onSlideStart(slide: number) {
		this.sliding = true;
	}

	private onSlideEnd(slide: number) {
		this.sliding = false;
	}

	getImgUrl(image: string) {
		return process.env.API_IMG_URL + image;
	}
}
