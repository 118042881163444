

































































import { Component, Vue, Prop, Watch } from 'vue-property-decorator';
import PageService from '~/services/PageService';
import Page from '~/models/page';
import PageSection from '~/models/page_section';
import PageSectionHeader from '~/components/core/page_sections/PageSectionHeader.vue';
import PageSectionSolutions from '~/components/core/page_sections/PageSectionSolutions.vue';
import PageSectionBanner from '~/components/core/page_sections/PageSectionBanner.vue';
import PageSectionProductGrid from '~/components/core/page_sections/PageSectionProductGrid.vue';
import PageSectionHighLightPoints from '~/components/core/page_sections/PageSectionHighLightPoints.vue';
import PageSectionProductConfiguration from '~/components/core/page_sections/PageSectionProductConfiguration.vue';
import PageSectionFeaturesAndServices from '~/components/core/page_sections/PageSectionFeaturesAndServices.vue';
import PageSectionProductDataSheet from '~/components/core/page_sections/PageSectionProductDataSheet.vue';
import PageSectionFaqs from '~/components/core/page_sections/PageSectionFaqs.vue';
import GtmService from "~/services/GtmService";

@Component({
	components: {
		PageSectionProductGrid,
		PageSectionBanner,
		PageSectionSolutions,
		PageSectionHeader,
		PageSectionHighLightPoints,
		PageSectionProductConfiguration,
		PageSectionFeaturesAndServices,
		PageSectionProductDataSheet,
		PageSectionFaqs,
	},
})
export default class PageContainer extends Vue {
	@Prop({ type: String, required: false }) slug: string | undefined;
	@Prop({ type: Boolean, default: false, required: false }) is_product: boolean | undefined;

	private page_slug: string = '';
	private page: Page = new Page();
	private socialImage = require('~/assets/img/a3satel-share.jpg');
	private page_slug_error = false;

	@Watch('$fetchState.pending')
	onFetchChanged(model: any) {
		if (this.$fetchState.pending) {
			this.$nuxt.$loading.start();
		} else if (!this.$fetchState.pending && Object.keys(this.$nuxt.$loading).length !== 0) {
			this.$nuxt.$loading.finish();
		}
	}

	get cartGtmLoaded() {
		return this.$store.state.cart_gtm_loaded;
	}

	get userManaged() {
		return this.$store.state.user_managed;
	}
	// Esto es para cuando se acceda directamente por URL o se recargue la página
	@Watch('cartGtmLoaded')
	onCartGtmLoaded() {
		if (this.is_product && this.cartGtmLoaded && this.userManaged && this.page.ec_product_id) {
			this.emitGTM();
		}
	}

	@Watch('userManaged')
	onUserManaged() {
		if (this.is_product && this.cartGtmLoaded && this.userManaged && this.page.ec_product_id) {
			this.emitGTM();
		}
	}

	head() {
		return {
			bodyAttrs: {
				class: this.getBodyClass(),
			},
			meta: [
				{ hid: 'og:image', property: 'og:image', content: process.env.APP_URL + this.socialImage },
				{ hid: 'og:image:alt', property: 'og:image:alt', content: '' },
				{ hid: 'og:image:type', property: 'og:image:type', content: 'image/jpeg' },
				{ hid: 'twitter:image:src', name: 'twitter:image:src', content: process.env.APP_URL + this.socialImage },
				{ hid: 'twitter:card', name: 'twitter:card', content: process.env.APP_URL + this.socialImage },
			],
		};
	}

	async fetch() {
		if (this.slug) {
			this.page_slug = this.slug;
		} else {
			this.page_slug = this.$route.params.slug;
		}
		await this.getPage();
	}

	mounted() {}

	getBodyClass() {
		if (this.page_slug_error) {
			return 'page-error';
		} else if (this.is_product) {
			return 'page-product';
		} else if (this.page_slug === '/') {
			return 'page-home';
		} else {
			return 'page-' + this.page_slug;
		}
	}

	async getPage() {
		await PageService.getBySlug(this.$axios, this.page_slug)
			.then((res: any) => {
				if (res) {
					this.page = res;
					this.page_slug_error = false;
					// Esto es para la navegación normal de usuario
					if (this.is_product && process.client) {
						this.emitGTM();
					}
				}
			})
			.catch((error: any) => {
				if (error.response.status === 450) {
					this.page_slug_error = true;
					return this.$nuxt.error({ statusCode: 404 });
				}
			});
	}

	emitGTM() {
        const prevEvent = this.$store.getters.getGtmEvent;
        if (prevEvent) {
            GtmService.emit(this.$gtm, this.$store, prevEvent, this.page);
            this.$store.commit('setGtmEvent', null);
        }
        GtmService.emit(this.$gtm, this.$store, 'view_item', this.page);
	}

	getComponentByType(type: number) {
		switch (type) {
			case PageSection.PAGE_SECTION_HEADER_TYPE:
			case PageSection.PAGE_SECTION_HEADER_PRODUCT_TYPE:
				return 'PageSectionHeader';
			case PageSection.PAGE_SECTION_SOLUTIONS_TYPE:
				return 'PageSectionSolutions';
			case PageSection.PAGE_SECTION_BANNER_TYPE:
				return 'PageSectionBanner';
			case PageSection.PAGE_SECTION_PRODUCT_GRID_TYPE:
				return 'PageSectionProductGrid';
			case PageSection.PAGE_SECTION_HIGHLIGHT_POINTS_TYPE:
				return 'PageSectionHighLightPoints';
			case PageSection.PAGE_SECTION_PRODUCT_CONFIGURATION_TYPE:
				return 'PageSectionProductConfiguration';
			case PageSection.PAGE_SECTION_PRODUCT_FEATURES_TYPE:
				return 'PageSectionFeaturesAndServices';
			case PageSection.PAGE_SECTION_PRODUCT_DATA_SHEET_TYPE:
				return 'PageSectionProductDataSheet';
			case PageSection.PAGE_SECTION_PRODUCT_FAQS_TYPE:
				return 'PageSectionFaqs';
		}
	}

	goToLegalTerm(slug: string) {
		// this.$store.commit('setGtmEvent', 'select_item');
		window.open('/legalTerm/' + slug, '_blank');
	}
}
