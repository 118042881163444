//
//
//
//
//
//
//
//
//
//
//

export default {
	name: 'Breadcrumb',
	computed: {
		crumbs() {
			const pathArray = this.$route.path.split('/');
			pathArray.shift();
			const breadcrumbs = pathArray.reduce((breadcrumbArray, path, idx) => {
				breadcrumbArray.push({
					to: breadcrumbArray[idx - 1] ? '/' + breadcrumbArray[idx - 1].path + '/' + path : '/' + path,
					title: path,
				});
				return breadcrumbArray;
			}, []);
			return breadcrumbs;
		},
	},
};
