























import { Component, Vue, Prop } from 'vue-property-decorator';
import Product from '~/components/core/Product.vue';
import PageService from '~/services/PageService';

@Component({
	components: { Product },
})
export default class PageSectionProductGrid extends Vue {
	@Prop({ type: Object, required: true }) content: any;

	private products = [];
	private filters: { category: any; status: number } = { category: null, status: PageService.PUBLISHED_STATUS };

	mounted() {
		const category = this.$route.query.category;
		if (category) {
			this.filters.category = category;
		}
		this.getProducts();
	}

	async getProducts() {
		await PageService.getProductList(this.$axios, this.filters, { sortBy: 'updated_at', sortDesc: true })
			.then((res: any) => {
				if (res) {
					this.products = res.data.elements;
				}
			})
			.catch((error: any) => {
				// ApiHelper.handleErrors(error, {}, this.$nuxt);
			});
	}
}
