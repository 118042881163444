





















import { Component, Vue, Prop } from 'nuxt-property-decorator';

@Component({})
export default class Feature extends Vue {
	@Prop({ default: null, required: true }) private productTitle!: string;
	@Prop({ default: null, required: true }) private productDescription!: string;
	@Prop({ default: null, required: true }) private productIcon!: string;
	@Prop({ default: null, required: false }) private productRoute!: string;
	@Prop({ default: null, required: false }) private productRouteText!: string;
}
