





























import { Component, Vue, Prop } from 'vue-property-decorator';
import ModalDemo from '~/components/modals/ModalDemo.vue';
import Page from '~/models/page';

@Component({
	components: { ModalDemo },
})
export default class PageSectionProductDataSheet extends Vue {
	@Prop({ type: Object, required: true }) content: any;
	@Prop({ required: false }) page!: Page;

	$refs!: {
		dataSheetModalDemo: InstanceType<typeof ModalDemo>;
	};

	showDemo() {
		this.$refs.dataSheetModalDemo.show(this.page.title);
	}
}
